<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="region.outlets"
          :search="search"
          item-key="id"
        >
          <!-- start of outlet template -->
          <template v-slot:item.CardName="{ item }">
            <v-btn color="blue lighten-1" text small :to="`/outlet/${item.id}`">
              {{
              item.CardName
              }}
            </v-btn>
          </template>
          <!-- end of outlet template -->

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      region: {},
      search: null,
      headers: [
        { text: "Name", value: "CardName" },
        { text: "Category", value: "CardCode" },
        { text: "Address", value: "Address" },
        { text: "Description", value: "description" },
      ],
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true,
    },
  },
  methods: {
    getData(val) {
      if (val) {
        const self = this;
        this.loading = true;
        this.$store
          .dispatch("get", `/territory/${val}`)
          .then((res) => {
            console.log(res, 'region');
            self.region = res;
            self.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
</style>